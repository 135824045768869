import {
  decodeTokenPayLoad,
  getCookieDomain,
  type IdTokenPayload as UiIdTokenPayload,
} from "@hkexpressairwayslimited/ui";
import crypto from "crypto";
import Cookies from "js-cookie";

function notAppAndNotLocal() {
  const urlParams = new URLSearchParams(window.location.search);
  const device = urlParams.get("device") || "web";
  if (process.env.NODE_ENV === "production" && device.toLowerCase() !== "app") return true; // for local development
  return false;
}

export function persistRefreshToken(refreshToken?: string) {
  // if (notAppAndNotLocal()) {
  //   return;
  // }
  if (!refreshToken) return;
  Cookies.set("refresh_token", refreshToken, { expires: 365, path: "/", domain: getCookieDomain() });
}

export function getRefreshToken(): string {
  return Cookies.get("refresh_token") || "";
}

export function clearRefreshToken() {
  Cookies.remove("refresh_token", { path: "/", domain: getCookieDomain() });
}

export type AccessTokenPayload = {
  sub: string;
  iss: string;
  client_id: string;
  origin_jti: string;
  event_id: string;
  token_use: string;
  scope: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  username: string;
};

export function persistAccessToken(accessToken?: string) {
  // if (notAppAndNotLocal()) {
  //   return;
  // }
  if (!accessToken) return;
  const payload = decodeTokenPayLoad<AccessTokenPayload>(accessToken);

  if (payload) {
    const expires = (payload.exp - payload.iat) / 60 / 60 / 24;

    Cookies.set("access_token", accessToken, { expires, path: "/", domain: getCookieDomain() });
  }
}

export function getAccessToken(): string {
  return Cookies.get("access_token") || "";
}

export function clearAccessToken() {
  Cookies.remove("access_token", { path: "/", domain: getCookieDomain() });
}

export function persistIdToken(idToken?: string) {
  // if (notAppAndNotLocal()) {
  //   return;
  // }
  if (!idToken) return;
  const payload = decodeTokenPayLoad<UiIdTokenPayload>(idToken);

  if (payload) {
    const expires = (payload.exp - payload.iat) / 60 / 60 / 24;

    Cookies.set("id_token", idToken, { expires, path: "/", domain: getCookieDomain() });
  }
}

export function persistMlcAccessToken(mlcAccessToken?: string) {
  // if (notAppAndNotLocal()) {
  //   return;
  // }
  if (!mlcAccessToken) return;
  const payload = decodeTokenPayLoad<AccessTokenPayload>(mlcAccessToken);

  if (payload) {
    const expires = (payload.exp - payload.iat) / 60 / 60 / 24;

    Cookies.set("mlc_access_token", mlcAccessToken, { expires, path: "/", domain: getCookieDomain() });
  }
}

export function getMlcAccessToken() {
  return Cookies.get("mlc_access_token") || "";
}

export function clearMlcAccessToken() {
  return Cookies.remove("mlc_access_token", { path: "/", domain: getCookieDomain() });
}

export { getIdToken } from "@hkexpressairwayslimited/ui";

export type idTokenPayload = UiIdTokenPayload;

export function clearIdToken() {
  Cookies.remove("id_token", { path: "/", domain: getCookieDomain() });
}

const FALLBACK_KEY = "fallback";
const ENCRYPTION_KEY = process.env.ENCRYPTION_KEY || "default_encryption_key";
const IV_LENGTH = 16; // For AES, this is always 16

function encrypt(text: string): string {
  const iv = crypto.randomBytes(IV_LENGTH);
  const cipher = crypto.createCipheriv("aes-256-cbc", Buffer.from(ENCRYPTION_KEY), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return iv.toString("hex") + ":" + encrypted.toString("hex");
}

function decrypt(text: string): string {
  const textParts = text.split(":");
  const iv = Buffer.from(textParts.shift()!, "hex");
  const encryptedText = Buffer.from(textParts.join(":"), "hex");
  const decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(ENCRYPTION_KEY), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}

export function getFallback() {
  const encryptedValue = sessionStorage.getItem(FALLBACK_KEY);
  return encryptedValue ? decrypt(encryptedValue) : null;
}
export function setFallback(value: string) {
  const encryptedValue = encrypt(value);
  return sessionStorage.setItem(FALLBACK_KEY, encryptedValue);
}
export function clearFallback() {
  return sessionStorage.removeItem(FALLBACK_KEY);
}
